import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map, tap } from 'rxjs';

import { NotificationService } from '@mp/shared/data-access';

import { HelpCenterService } from '../services';

import { HelpCenterActions } from './help-center.actions';

@Injectable()
export class HelpCenterEffects {
  fetchHelpCenterSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HelpCenterActions.fetchHelpCenterSettings),
      fetch({
        run: () => {
          return this.service
            .getHelpCenterSettings()
            .pipe(
              map((helpCenterSettings) => HelpCenterActions.fetchHelpCenterSettingsSuccess({ helpCenterSettings })),
            );
        },
        onError: () => {
          this.notificationService.toastDanger('Beim Laden der Hilfe Center Einstellungen ist ein Fehler aufgetreten.');
          return HelpCenterActions.fetchHelpCenterSettingsError();
        },
      }),
    );
  });

  updateHelpCenterSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HelpCenterActions.updateHelpCenterSettings),
      fetch({
        run: ({ helpCenterSettings }) => {
          return this.service.updateHelpCenterSettings(helpCenterSettings).pipe(
            tap(() => this.notificationService.toastSuccess('Die Hilfe Center Einstellungen wurden aktualisiert.')),
            map((helpCenterSettings) => HelpCenterActions.updateHelpCenterSettingsSuccess({ helpCenterSettings })),
          );
        },
        onError: () => {
          this.notificationService.toastDanger(
            'Beim Aktualisieren der Hilfe Center Einstellungen ist ein Fehler aufgetreten.',
          );
          return HelpCenterActions.updateHelpCenterSettingsError();
        },
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly service: HelpCenterService,
    private readonly notificationService: NotificationService,
  ) {}
}
